import { LOCALES } from '../locales'

export default {
    [LOCALES.KHMER]: {
        "login": "Login",

        // Menu
        "dashboard": "ព័ត៌មានសង្ខេប",
        "product": "ផលិតផល",
        "cate": "ក្រុម",
        "brand": "ម៉ាក",
        "type": "ប្រភេទ",
        "inventory": "សារពើភ័ណ្ឌ",
        "store": "ហាង",
        "warehouse": "ឃ្លាំង",
        "receive-po": "ទទួល ស្តុក",
        "purchasing": "បញ្ជាទិញ",
        "po": "ធ្វើការបញ្ជាទិញ",
        "setprice": "កំណត់តម្លៃ",
        "pricing": "តារាងតម្លៃ",
        "check-price": "ពិនិត្យតម្លៃ",
        "marketing": "ធ្វើទីផ្សារ",
        "program": "កម្មវិធី",
        "loyalty": "Loyalty",
        "e-wallet": "កាបូប",
        "people": "មនុស្ស",
        "user": "អ្នកប្រើប្រាស់",
        "vendor": "អ្នកផ្គត់ផ្គង់",
        "customer": "អតិថិជន",
        "group": "ក្រុម",
        "report": "របាយការណ៍",
        "sale": "លក់",
        "expense": "ចំណាយ",
        "setting": "ការកំណត់",
        "general": "ទូទៅ",
        "role": "តួនាទី",
        "counter": "បេឡា",
        "currency": "អត្រាប្តូរប្រាក់",
        "profile": "ប្រវត្តិរូប",
        "support": "ជំនួយ",

        // Base Index Layout
        "search": "ស្វែងរក",
        "tool": "ឧ.ក",
        "total": "សរុប",
        "no-data": "គ្មានទិន្នន័យ",
        "no": "ល.រ",
        "action": "សកម្មភាព",

        // Product
        "all": "ផលិតផលទាំងអស់",
        "code": "កូដ",
        "sku": "SKU",
        "product-name": "ឈ្មោះផលិតផល",

        // User
        "create-user": "បញ្ចូលអ្នកប្រើប្រាស់",
        "edit-user": "កែប្រែអ្នកប្រើ",
        "username": "ឈ្មោះសម្គាល់",
        "email": "អ៊ីមែល",
        "phone-number": "លេខទូរស័ព្ទ",
        "designation": "តួនាទី",
        "image": "រូប",
        "first-name": "នាម",
        "last-name": "ត្រកូល",
        "address": "អាស័យដ្ឋាន",
        "city": "ទីក្រុង",
        "zipcode": "លេខកូដតំបន់",
        "country": "ប្រទេស",
        "map-url": "ទីតាំងលើផែនទី",
        "role-active": "ត្រូវការបង្កើតតួនាទីថ្មី?",
        "security": "សន្តិសុខ",
        "password": "ពាក្យសម្ងាត់",
        "confirm-pwd": "បញ្ជាក់ពាក្យសម្ងាត់",
        "shift-time": "ពេលវេលាផ្លាស់ប្តូរ",
        "confirm-pwd": "បញ្ជាក់ពាក្យសម្ងាត់",
        "confirm-pwd": "បញ្ជាក់ពាក្យសម្ងាត់",
        "name": "ឈ្មោះ",
        "time": "ពេលវេលា",

        // Entity Base Layout
        "save": "រក្សាទុក",
        "discard": "បោះបង់",
        "update": "ធ្វើបច្ចុប្បន្នភាព",
        "print": "បោះពុម្ព",
        "upload-pic": "បញ្ចូលរូបភាព",
        "description": "ការពិពណ៌នា",
        "company": "ក្រុមហ៊ុន",
        "full-name": "ឈ្មោះពេញ",
        "create-group": "បង្កើតក្រុមថ្មី",
        "edit-group": "កែប្រែក្រុម",
        "view-group": "ចូលមើលក្រុម",
        "create-vendor": "បញ្ចូលអ្នកផ្គត់ផ្គង់",
        "edit-vendor": "កែប្រែអ្នកផ្គត់ផ្គង់",
        "view-vendor": "ចូលមើលអ្នកផ្គត់ផ្គង់",
        "create-product": "បញ្ចូលផលិតផល",
        "edit-product": "កែប្រែផលិតផល",
        "show-product": "បង្ហាញផលិតផល",
        "create-brand": "បញ្ចូលម៉ាក",
        "edit-brand": "កែប្រែម៉ាក",
        "show-brand": "បង្ហាញម៉ាក",
        "create-cate": "បង្កើតក្រុមថ្មី",
        "edit-cate": "កែប្រែក្រុម",
        "show-cate": "បង្ហាញក្រុម",
        "create-sub-cate": "បង្កើតអនុក្រុម",
        "edit-sub-cate": "កែប្រែអនុក្រុម",
        "show-sub-cate": "បង្ហាញអនុក្រុម",
        "edit-type": "កែប្រែប្រភេទ",
        "show-type": "បង្ហាញប្រភេទ",
        "pwd-active": "អ្នកចង់ប្តូរពាក្យសម្ងាត់ថ្មី?",
        "brand-active": "ធ្វើឱ្យម៉ាកនេះអាចប្រើបាន?",
        "tags": "ស្លាក",
        "status": "ស្ថានភាព",
        "available": "អាចប្រើបាន",
        "unavailable": "មិនអាចប្រើបាន",
        "sub-cate": "អនុក្រុម",
        "prefix": "អក្សផ្តើម",
        "pn-sku": "កូដ/SKU",
        "model": "គំរូ",
        "native-name": "ឈ្មោះជាភាសាជាតិ",
        "variant": "វ៉ារ្យ៉ង់",
        "value": "តម្លៃ",
        "specification": "ការបញ្ជាក់",
        "variant-active": "តើផលិតផលនេះមានវ៉ារ្យ៉ង់?",
        "no-variant": "គ្មានវ៉ារ្យ៉ង់",
        "unit": "ឯកតា",
        "priority": "លេខរៀងអាទិភាព",
        "inhouse-product": "ផលិតផលផ្ទាល់ខ្លួន",
        "product-image": "រូបផលិតផល",
        "import": "ទាញចូល",
        "export": "នាំចេញ",
        "template": "ទំរងគំរូង",
        "contact-info": "ព័ត៌មានទំនាក់ទំនង",
        "place": "ទីកន្លែង",
        "resturant-ktv": "កន្លែងកំសាន្ត",
        "area": "តំបន់",
        "table": "តុ",
        "room": "បន្ទប់",
        "full-name": "ឈ្មោះពេញ",
        "fax": "ទូរសារ",
        "tin": "អតប",
        "tin-full": "លេខអត្តសញ្ញាណសារពើពន្ធ", 
        "registered-at": "បានចុះបញ្ជី",
        "main-business": "អាជីវកម្មចម្បង",
        "under": "នៅក្រោម",
        "form-business": "ទម្រង់អាជីវកម្ម",
        "nationality": "សញ្ជាតិ",
        "facebook": "Facebook",
        "website": "វិបសាយ",
        "gender": "ភេទ",
        "group-active": "ចង់បង្កើតក្រុមថ្មី?",
        "create-customer": "បញ្ចូលអតិថិជនថ្មី",
        "edit-customer": "កែប្រែអតិថិជន",
        "view-customer": "ចូលមើអតិថិជន",
        "male": "ប្រុស",
        "female": "ស្រី",
        "permission": "សិទ្ធិ~អនុញ្ញាត",
        "create-role": "បង្កើតតួនាទី",
        "edit-role": "កែប្រែតួនាទី",
        "change-pwd": "ចង់ផ្លាស់ប្តូរពាក្យសម្ងាត់ថ្មី?",
        "wifi-name": "ឈ្មោះ WiFi",
        "wifi-pwd": "ពាក្យសម្ងាត់ WiFi",
        "thank-you-text": "ឃ្លា សូមអរគុណ",
        "position-info": "ព័ត៌មានតួនាទី",
        "position": "តួនាទី",
        "create-warehouse": "បង្កើតឃ្លាំង",
        "edit-warehouse": "កែប្រែឃ្លាំង",
        "view-warehouse": "បង្ហាញឃ្លាំង",
        "product-name": "ឈ្មោះផលិតផល",
        "brand-name": "ឈ្មោះម៉ាក",
        "create-set-price": "អនុញ្ញាតកំណត់តម្លៃ",
        "edit-set-price": "កែប្រែកំណត់តម្លៃ",
        "product-info": "ព័ត៌មានផលិតផល",
        "price-to-lvl": "កម្រិតតម្លៃ",
        "start-price": "តម្លៃតូចបំផុត",
        "end-price": "តម្លៃធំបំផុង",
        "attach-file": "ឯកសារភ្ជាប់",
        "image-file": "រូប និង ឯកសារ",
        "qty": "ចំ.ន",
        "back": "ត្រឡប់ក្រោយ",
        "bundle": "ផ្គួប",
        "files": "ឯកសារ",
        "store-location": "ទីតាំងហាង",
        "warehouse-location": "ទីតាំឃ្លាំង",
        "staff": "បុគ្គលិក",
        "create-staff": "បញ្ចូលបុគ្គលិក",
        "edit-staff": "កែប្រែបុគ្គិក",
        "department": "នាយកដ្ឋាន",
        "create-department": "បញ្ចូលនាយកដ្ឋាន",
        "edit-department": "កែប្រែនាយកដ្ឋាន",
        "po-num": "លេខបញ្ជាទិញ",
        "payment": "ការទូទាត់",
        "create-by": "បង្កើតដោយ",
        "receive-by": "ទទួលនៅ",
        "create-at": "បង្កើតនៅ",
        "update-at": "ធ្វើបច្ចុប្បន្នភាពនៅ",
        "cost": "ថ្លៃដើម",
        "total-cost": "ថ្លៃដើមសរុប",
        "made-date": "កាលបរិច្ឆេទផលិត",
        "exp-date": "កាលបរិច្ឆេទផុតកំណត់",
        "remark": "កំណត់ចំណាំ",
        "cost-active": "តើនេះមានការចំណាយផ្សេងទៀតឬទេ?",
        "deposit": "បង់មុន",
        "tax": "ពន្ធ",
        "estimate-arrival": "ប៉ាន់ស្មានការមកដល់",
        "note": "ចំណាំ",
        "color": "ពណ៌",
        "clear-by": "ទូទាន់ដោយ",
        "create-po": "បញ្ជាទិញ",
        "edit-po": "កែប្រែការបញ្ជាទិញ",
        "receive-po": "ទទួលស្តុក",
        "edit-receive-po": "កែប្រែការទទួលស្តុក",
        "receive": "ទទួល",
        "return": "បញ្ជូនត្រលប់",
        "amount": "ចំនួនទឹកប្រាក់",
        "ordering": "កំពុងកាម៉ង់",
        "receiving": "កំពុងទទួល",
        "issued-considering": "ពិចារណាលើបញ្ហា",
        "returning-all": "បញ្ជូនត្រលប់ទាំងអស់",
        "returning-some-items": "បញ្ជូនទំនិញត្រលប់ខ្លះ",
        "completed-received-all": "ទទួលបានទាំងអស់",
        "completed-by-over": "បិទបញ្ចប់-ទទួលបានលើចំនួន",
        "completed-by-missing": "បិទបញ្ចប់-បាត់ខ្លះ",
        "completed-by-broken": "បិទបញ្ចប់- ខូច - មិនបញ្ជូនត្រលប់",
        "completed-by-return-some": "បិទបញ្ចប់- បញ្ជូនត្រលប់ខ្លះ",
        "serial-number": "លេខសម្គាល់",
        "warranty": "ការធានា",
        "receive-to": "ទទួលទៅកាន់",
        "clear-po": "ទូទាត់ការបញ្ជាទិញ",
        "deposit-remark": "កំណត់សម្គាល់ប្រាក់កក់",
        "total-po": "សរុបការបញ្ជាទិញ",
        "vendor-address": "អាសយដ្ឋានអ្នកលក់",
        "total-ordering": "សរុបកំពុងកាម៉ង់",
        "total-receiving": "សរុបបានទទួល",
        "total-succeed": "សរុបបញ្ចប់ជោគជ័យ",
        "total-issued": "សរុបជួបបញ្ហា",
        "total-cancelled": "សរុបបោះបង់",
        "total-draft": "សរុបពង្រៀង",
        "total-delete": "សរុបបានលុប",
        "total-amount": "សរុបទឹកប្រាក់",
        "total-paid": "សរុបបានទូទាត់",
        "total-credit": "សរុប-ជំពាក់",
        "draft": "ព្រៀង",
        "cancelled": "បោះបង់",
        "date": "កាលបរិច្ឆេទ",
        "product-code": "កូដផលិតផល",
        "adjustment": "ការកែតម្រូវ",
        "booking": "កក់ទុក",
        "reserve": "បម្រុងទុក",
        "request": "ស្នើស្តុក",
        "transfer": "ផ្ទេរ",
        "warehouse-request": "ស្នើពីឃ្លាំ",
        "warehouse-transfer": "ផ្ទេរពីឃ្លាំង",
        "store-request": "ស្នើពីហាង",
        "store-transfer": "ផ្ទេរពីហាង",
        "ref-quot-no": "លេខសម្រង់យោង",
        "refer-down-pay-no": "លេខយោងលើការបង់ប្រាក់",
        "project-name": "ឈ្មោះគម្រោង",
        "client": "អតិថិជន",
        "sale-represent": "តំណាងអ្នកលក់",
        "num-of-day": "ចំនួនថ្ងៃ",
        "reason": "មូលហេតុ",
        "create-booking": "កក់",
        "edit-booking": "កែប្រែការកក់",
        "check-booking": "ពិនិត្យការកក់",
        "view-booking": "ចូលមើការកក់",
        "ok": "OK",
        "cancel": "បោះបង់",
        "instock": "មានស្តុក",
        "information": "ព័ត៌មាន",
        "ref-pro-inv-no": "លេខឯកសារយោងវិក័យប័ត្រProforma",
        "next-pay-est-date": "កាលបរិច្ឆេទប៉ាន់ស្មានការទូទាត់បន្ទាប់",
        "request-from": "ស្នើសុំពី",
        "request-to": "ស្នើសុំទៅកាន់",
        "core-feature": "មុខងារស្នូល",
        "technical-sale": "បច្ចេកទេសការលក់",
        "executive-summary": "ព័ត៌មានសង្ខេប",
        "full-description": "ការពិពណ៌នាពេញលេញ",
        "attached-file": "ឯកសារភ្ជាប់",
        "this-week": "សប្តាហ៍នេះ",
        "this-month": "ខែនេះ",
        "this-year": "ឆ្នាំនេះ",
        "nearly-out-of-stock": "ជិតអស់ស្តុក",
        "nearly-expire-product": "ផលិតផលជិតផុតកំណត់",
        "cut-stock": "កាត់ស្តុក",
        "add-stock": "បញ្ចូលស្តុក",
        "current-stock": "ស្តុកបច្ចុប្បន្ន",
        "broken": "ខូច",
        "lost": "បាត់",
        "other": "ផ្សេងៗ",
        "additional-stock": "ដាក់បន្ថែមស្តុក",
        "vendor-promotion": "អ្នកលក់ប្រូម៉ូសិន",
        "staff": "បុគ្គលិក",
        "animal": "សត្វ",
        "life-value": "វត្តនៃតម្លៃ",
        "penalty": "ពិន័យ",
        "extra-cost": "ការចំណាយបន្ថែម",
        "by": "ដោយ",
        "open-reviewing": "បើក-កំពុងពិនិត្យ",
        "open-rejected": "បើក-ច្រានចោល",
        "closed-approved": "បិទ-បានអនុម័ត",
        "closed-rejected": "បិទ-ច្រានចោល",
        "own-request": "ស្នើដោយខ្លួនឯង",
        "own-transfer": "ផ្ទេរដោយខ្លួនឯង",
        "other-request": "ស្នើដោយអ្នកដទៃ",
        "other-transfer": "ផ្ទេរដោយអ្នកដទៃ",
        "reviewing": "កំពុងពិនិត្យ",
        "revision-item": "កែប្រែ ផលិតផល",
        "reject": "ច្រានចោល",
        "accepted": "បានទទួលយក",
        "preparing": "កំពុងរៀបចំ",
        "delivering": "កំពុងដឹកជញ្ជូន",
        "receiving": "កំពុងទទួល",
        "completed": "បានបញ្ចប់",
        "execution": "អនុវត្ត",
        "issue-date": "ធ្វើនៅ",
        "lastest-update": "បច្ចុប្បន្នភាពចុងក្រោយ",
        "record-number": "លេខកំណត់ត្រា",
        "transfer-to": "ផ្ទេរទៅកាន់",
        "request-from": "ស្នើពី",
        "transfer-from": "ផ្ទេរពី",
        "acknowledge-by": "ទទួលស្គាល់ដោយ",
        "point": "ពន្ទុក",
        "set-point": "កំណត់ពិន្ទុ",
        "start-point": "ពិន្ទុតូចបំផុត",
        "end-point": "ពិន្ទុធំបំផុត",
        "loyalty-active": "ធ្វើឱ្យLoyaltyអាចប្រើបាន",
        "create-loyalty": "បង្កើត Loyalty",
        "edit-loyalty": "កែប្រែ Loyalty",
        "balance": "តុល្យភាព",
        "serial": "សៀរៀល",
        "create-counter": "បង្កើតបេឡា",
        "edit-counter": "កែប្រែបេឡា",
        "create-e-wallet": "បង្កើតកាបូបលុយ",
        "top-up-e-wallet": "បញ្ចូលលុយ",
        "dollar": "ដុល្លារ",
        "riel": "រៀល",
        "stand-alone-active": "តើនេះជាកម្មវិធីឯករាជ្យ?",
        "create-program": "បង្កើតកម្មវិធី",
        "edit-program": "កែប្រែកម្មវិធី",
        "check-program": "ពិនិត្យកម្មវិធិ",
        "view-program": "ចូលមើកម្មវិធី",
        "program-name": "ឈ្មោះកម្មវិធី",
        "program-id": "លេខសម្គាល់កម្មវិធី",
        "message": "សារជូនដំណឹង",
        "price-limit": "តម្លៃទៀបបំផុត",
        "cash-type": "ប្រភេទប្រាក់",
        "schedule": "កាលវិភាគ",
        "item": "ទំនិញ",
        "is-zero": "ស្តុកតូចជាងឬស្មើ០",
        "create-request": "ស្នើ",
        "edit-request": "កែប្រែការស្នើ",
        "check-request": "ពិនិត្យការស្នើ",
        "view-request": "ចូលមើលការស្នើ",
        "create-transfer": "ធ្វើការផ្ទេរ",
        "edit-transfer": "កែប្រែការផ្ទេរ",
        "check-transfer": "ពិនិត្យការផ្ទេរ",
        "view-transfer": "ចូលមើលការផ្ទេរ",
        // "edit-counter": "កែប្រែបេឡា",
        "invoice": "វិក័យប័ត្រសរុប",
        "today-invoice": "វិក័យប័ត្រថ្ងៃនេះ",
        "sale-revenue": "ចំណូលការលក់សរុប",
        "today-sale-revenue": "ចំណូលការលក់ថ្ងៃនេះ",
        "today": "ថ្ងៃនេះ",
        "week": "សប្តាហ៍នេះ",
        "month": "ខែនេះ",
        "year": "ឆ្នាំនេះ",
        "yesterday": "ម្សិលមិញ",
        "last-week": "សប្តាហ៍មុន",
        "last-month": "ខែមុន",
        "last-year": "ឆ្នាំមុន",
        "credit": "ជំពាក់",
        "sale-report": "របាយការណ៍លក់",
        "po-report": "របាយការណ៍បញ្ជាទិញ",
        "today-credit": "ជំពាក់ថ្ងៃនេះ",
        "new-arrival": "មកដល់ថ្មី",
        "nearly-exp": "ជិតផុតកំណត់",
        "nearly-out": "ជិតអស់ស្តុក",
        "financial": "ហិរញ្ញវត្ថុ",
        "invoice-num": "លេខវិក័យប័ត្រ",
        "receive-dollar": "ទទួល ($)",
        "receive-riel": "ទទួល (KHR)",
        "credit-dollar": "ជំពាក់ ($)",
        "credit-riel": "ជំពាក់ (KHR)",
        "total-dollar": "សរុប ($)",
        "total-riel": "សរុប (KHR)",
        "method": "តាមរយៈ",
        "withdraw": "ដកប្រាក់",
        "product-report": "របាយការណ៍ផលិតផល",
        "inventory-report": "របាយការណ៍សារពើភ័ណ្ឌ",
        "main-balance": "សមតុល្យគោល",
        "num-of-exp": "ចំនួនថ្ងៃជូនដំណឹងនៃកាលបរិច្ឆេទផុតកំណត់",
        "num-of-out-stock": "ចំនួនស្តុកត្រូវជូនដំណឹងនៃការជិតអស់",
        "money-in": "លុយចូល",
        "money-out": "លុយចេញ",
        "is-serial": "មានលេខសៀរៀល?",
        "accounting": "គណនី",
        "credit-management": "គ្រប់គ្រងបំណុល",
        "credit-collection": "ការប្រមូលបំណុល",
        "credit-settlement": "បង់បំណុល",
        "total-clients": "អតិថិជនសរុប",
        "total-transactions": "ប្រតិបត្តិការសរុប",
        "total-credit-settled": "បង់បំណុលសរុប",
        "due-credit-balance": "បំណុលនៅសលក់",
        "client-id": "លេខសម្គាល់អតិថិជន",
        "client-name": "ឈ្មោះអតិថិជន",
        "amount-should-be-settled": "ចំនួនប្រាក់គួរសង",
        "settle": "សង",
        "clear-credit": "ទូទាត់បំណុល",
        "invoice-number": "លេខវិក័យប័ត្រ",
        "sale-representative": "តំណាងអ្នកលក់",
        "down-payment": "បង់លុយមុន",
        "purchased-date": "កាលបរិច្ឆេទបានទិញ",
        "latest-updated-date": "ធ្វើបច្ចុប្បន្នភាពចុងក្រោយ",
        "estimate-return-date": "រំពឹងទុកសងត្រឡប់",
        "sub-total": "សរុបរង",
        "discount": "បញ្ចុះតម្លៃ",
        "vat": "ពន្ធ",
        "grand-total": "សរុបរួម",
        "account": "គណនី",
        "loging": "កំពុងចូល...",
        "product-management": "គ្រប់គ្រងផលិតផល",
        "auto-settle": "សងស្វ័យប្រវត្តិ",
        "view-credit": "ចូលមើបំណុល",
        "credit-balance": "បំណុលនៅសល់",
        "english": "English",
        "khmer": "ខ្មែរ",
        "create-type": "បង្កើតប្រភេទ",
        "create-adjustment": "បង្កើតការកែតម្រូវ",
        "inventory-management": "គ្រប់គ្រងសារពើភ័ណ្ឌ",
        "view-receive-po": "ចូលមើលការទទួលស្តុក",
        "hr": "ធនធានមនុស្ស",
        "create-client": "បញ្ចូលអតិថិជន",
        "edit-client": "កែប្រែអតិថិជន",
        "view-client": "ចូលមើលអតិថិជន",
        "view-po": "ចូលមើលការបញ្ជាទិញ",
        "sale-history": "ប្រវត្តិការលក់",
        "sale-cash-management": "គ្រប់គ្រងសាច់ប្រាក់ការលក់",
        "sale-client-management": "គ្រប់គ្រងអតិថិជន",
        "payway": "ភ្នាក់ងារធនាគា",
        "debit": "កាតVisa/Master",
        "marketing-report": "របាយការណ៍ការធ្វើទីផ្សារ",
        "tax-value": "ថ្លៃពន្ធ",
        "service-value": "ថ្លៃសេវា",
        "tax-status": "តើហាងនេះគិតពន្ធបញ្ចូលដែរឬទេ?",
        "service-status": "តើហាងនេះគិតថ្លៃសេវាបន្ថែមឬទេ?",
        "use-native-name": "ចង់ប្រើភាសជាតិ?",
        "logo": "និមិត្តសញ្ញា",
        "full-logo": "និមិត្តសញ្ញាពេញ",
        "kot-department": "ផ្នែកចុងភៅ",
        "is-kitchen": "ចង់បង្ហាញទៅកាន់ចុងភៅ?",
        "reward-point": "ពិន្ទុរង្វាន់ 1$ = Xពិន្ទុ",
        "exchange-reward-point": "ផ្លាស់ប្តូរពិន្ទុរង្វាន់ 1 ពិន្ទុ = Xដុល្លារ",
        "member-point": "ពិន្ទុសមាជិក 1$ = Xពិន្ទុសមាជិក",
        "all-brands": "ម៉ាកទាំងអស់",
        "all-categories": "ក្រុមទាំងអស់",
        "all-sub-categories": "អនុក្រុមទាំងអស់",
        "all-types": "ប្រភេទទាំងអស់",
        "price": "តម្លៃ",
        "update-by": "ធ្វើបច្ចុប្បន្នភាពដោយ",
        "approve-by": "អនុម័តដោយ",
        "item-type": "ប្រភេទទំនិញ",
        "client-type": "ប្រភេទអតិថិជន",
        "rule": "ច្បាប់",
        "number-of-exp": "ចំនួនថ្ងៃផុតកំណត់",
        "number-of-new-arrival": "ចំនួននៃការមកដល់ថ្មី",
        "membercard": "កាតសមាជិក",
        "tel": "លេខទូរស័ព្ទ",
        "contact": "ទំនាក់ទំនង",
        "sale-insight": "ការលក់លំអិត",
        "sale-detail": "អំពីការលក់",
        "item-wise": "អំពីទំនិញ",
        "cash-control": "ចរន្តសាច់ប្រាក់",
        "total-sold": "លក់សរុប",
        "grand-total-dollar": "សរុបរួម ($)",
        "grand-total-riel": "សរុបរួម (KHR)",
        "total-receive-dollar": "សរុបបានទទួល ($)",
        "total-receive-riel": "សរុបបានទទួល (KHR)",
        "total-change-dollar": "សរុបបានអាប់ ($)",
        "total-change-riel": "សរុបបានអាប់ (KHR)",
        "change-dollar": "អាប់ ($)",
        "change-riel": "អាប់ (KHR)",
        "receipt-no": "លេខបង្កាន់ដៃបង់ប្រាក់",
        "cashier": "អ្នកគិតលុយ",
        "total-bills": "វិក័យប័ត្រសរុប",
        "sale-summary": "សង្ខេបការលក់",
        "gross-sale": "លុយលក់សរុប",
        "net-sale": "លុយលក់សុទ្ធ",
        "cash-in": "លុយដាក់ចូល",
        "expensed": "លុយចំណាយ",
        "sold": "បានលក់",
        "changed": "បានអាប់",
        "cash": "សាច់ប្រាក់",
        "paid": "បានទូទាត់",
        "transaction": "ប្រតិបត្តិការ",
        "search-client": "ស្វែងរកអតិថិជន",
        "customer-service-id": "លេខអ្នកបំរើសេវា",
        "adjustment-cut": "ការកែតម្រូវការកាត់",
        "adjustment-add": "ការកែតម្រូវការបន្ថែម",
        "booking-by": "កក់ដោយ",
        "reserve-by": "បម្រុងទុកដោយ",
        "use-promotion": "តើហាងនេះត្រូវការប្រើ ប្រព័ន្ធទីផ្សារស្វ័យប្រវត្តិដែរឬទេ?",
        "payment-qr-dollar": "QR Codeបា្រក់ដុល្លារ",
        "payment-qr-riel": "QR Codeបា្រក់រៀល",
        "busy-status": "ស្ថានភាពរវល់",
        "counter-active": "បើឲ្យម៉ាស៊ីនបេឡានេះអាចប្រើបាន",
        "icon": "រូបតំណាង",
        "create-payway": "បង្កើតភ្នាក់ងារធនាគា",
        "edit-payway": "កែប្រែភ្នាក់ងារធនាគា",
        "create-debit": "បង្កើតកាតឥណទាន",
        "edit-debit": "កែប្រែកាតឥណទាន",
        "sales-reports": "របាយការណ៍ការលក់",
        "sales-history": "ប្រវត្តិការលក់",
        "sale-cash-in-out": "លុយ ចូល-ចេញ",
        "sale-currency-exchange": "អត្រាប្តូរប្រាក់",
        "sale-e-wallet": "កាបូប",
        "sale-user-profile": "កម្រងព័ត៌មានអ្នកប្រើប្រាស់",
        "total-paid-status": "ស្ថានភាពបានទូទាត់សរុប",
        "total-credit-status": "ស្ថានភាពបំណុលសរុប",
        "total-withdraw": "ការដកប្រាក់សរុប",
        "select": "ជ្រើសរើស",
        "each-set-price": "តម្លៃនីមួយៗ",
        "new": "បន្ថែម",
        "receipt": "បង្កាន់ដៃ",
        "bill-no": "លេខវិក័យប័ត្រ",
        "sales-associate": "សហការីផ្នែកលក់",
        "paid-by": "បានទូទាត់ដោយ",
        "dis": "ប.ត",
        "received": "បានទទួល",
        "exchange-rate": "អត្រាប្តូរប្រាក់",
        "pre-sale": "សហការីផ្នែកលក់",
        "counter-is-logout": "បិទទូបេឡា",
        "total-walk-in": "អតិថិជនទូទៅសរុប",
        "today-walk-in": "សរុបអតិថិជនទូទៅថ្ងៃនេះ",
        "walk-in": "អតិថិជនទូទៅ",
        "price-list": "តារាងតម្លៃ",
        "received-payment-remark": "កំណត់ចំណាំទទួលប្រាក់",
        "due-balance": "បំណុលនៅសល់",
        "total-point": "ពិន្ទុសរុប",
        "set-price": "កំណត់តម្លៃ",
        "cooking-time": "រយៈពេលចំអិន/ធ្វើ",
        "create-kot-department": "បង្កើតផ្នែកចុងភៅ",
        "edit-kot-department": "កែប្រែផ្នែកចុងភៅ",
        "is-pre-sale": "តើជាសហការីផ្នែកលក់?",
        "department-active": "បង្កើតនាយកដ្ឋានថ្មី?",
        "sale-receipt": "បង្កាន់ដៃការលក់",
        "sale-bill": "វិក័យប័ត្រការលក់",
        "pos-shift": "វេនផ្លាស់ប្តូរកាលក់",
        "payment-received": "បានទទួលការទូទាត់",
        "expenses": "ចំណាយ",
        "total-bill": "សរុបវិក័យប័ត្រ",
        "item-code": "លេខកូដទំនិញ",
        "category": "ក្រុម",
        "sub-category": "អនុក្រុម",
        "qty-sold": "ចំ.ន បានលក់",
        "total-customer": "អតិថិជនសរុប",
        "total-item-sold": "ទំនិញបានលក់សរុប",
        "shift-no": "លេខវេន",
        "cashier-name": "ឈ្មោះអ្នកគិតលុយ",
        "open-date": "កាលបរិច្ឆេទបើក",
        "close-date": "កាលបរិច្ឆេទបិទ",
        "close-usd": "បិទ USD",
        "close-khr": "បិទ KHR",
        "different-usd": "ចំនួនខុសគ្នា USD",
        "different-khr": "ចំនួនខុសគ្នា KHR",
        "transaction-details": "ប្រតិបត្តិការលំអិត",
        "total-payway": "ទទួលតាមភ្នាក់ងារធនាគាសរុប",
        "total-expense": "ចំណាយសរុប",
        "total-transaction": "ប្រតិបត្តិការសរុប",
        "create-store": "បង្កើតហាង",
        "edit-store": "កែប្រែហាង",
        "account-name": "ឈ្មោះគណនី",
        "account-native-name": "ឈ្មោះគណនីជាភាសាជាតិ",
        "payway-name": "ឈ្មោះភា្នក់ងារ",
        "payway-native-name": "ឈ្មោះភា្នក់ងារជាភាសាជាតិ",
        "account-card": "លេខគណនី",
        "payway-link": "តំណភ្នាក់ងារ",
        "payway-active": "បើក?",
        "is-dollar-status": "ជាសាច់ប្រាក់ដុល្លារ",
        "link": "តំណ",
        "paid-invoice": "វិក័យប័ត្របានទូទាត់",
        "void-invoice": "វិក័យប័ត្រទុកជាមោឃៈ",
        "total-invoice": "វិក័យប័ត្រសរុប",
        "cash-in-amount": "ចំនួនប្រាក់ដាក់ចូល",
        "receive-amount": "ចំនួនប្រាក់បានទទួល",
        "total-change": "លុយអាប់សរុប",
        "total-expensed": "បានចំណាយសរុប",
        "total-balance": "សមតុល្យសរុប",
        "received-via-payway": "បានទទួលតាមភ្នាក់ងារ",
        "real-cash-count": "សាច់ប្រាក់រាប់ជាក់ស្តែង",
        "different-cash": "សាច់ប្រាក់ខុសគ្នា",
        "reason-of-different": "ហេតុខុសគ្នា",
        "total-qty": "ចំនួនសរុប",
        "payment-group": "ក្រុមទូទាត់",
        "card-topup": "បញ្ចូលកាត",
        "stock": "ស្តុក",
        "in": "ចូល",
        "out": "ចេញ",
        "waiter": "អ្នករត់តុ",
        "exchage": "ប្តូរ",
        "change": "អាប់",
        "capital": "មូលធន",
        "check-in": "កត់ឈ្មោះចូល",
        "check-out": "កត់ឈ្មោះចេញ",
        "subscription": "ការជាវប្រចាំ",
        "view-subscription": "ចូលមើការជាវប្រចាំ",
        "remaining-day": "ចំនួនថ្ងៃនៅសល់",
        "bill-cycle": "វដ្តវិក័យប័ត្រ",
        "service-subscription": "ការជាវសេវាជាប្រចាំ",
        "tools": "ឧ.ក",
        "duration": "រយៈពេល",
        "remaining": "នៅសល់",
        "client-management": "គ្រប់គ្រងអតិថិជន",
        "restaurant-ktv": "កន្លែងកំសាន្ត",
        "create-area": "បញ្ចូលតំបន់",
        "edit-area": "កែប្រតំបន់",
        "create-table": "បញ្ចូលតុ",
        "edit-table": "កែប្រែតុ",
        "create-room": "បញ្ចូលបន្ទប់",
        "edit-room": "កែប្រែបន្ទប់",
        "rental-room": "បន្ទប់ជួល",
        "create-rental-room": "បញ្ចូលបន្ទប់ជួល",
        "edit-rental-room": "កែប្រែបន្ទប់ជួល",
        "available-store": "ដាក់ឲ្យដំណើរការលើហាង",
        "profit-and-loss": "ចំណេញ និងខាត",
        "account-receivable": "គណនីអតិថិជនជំពាក់",
        "account-payable": "គណនីជំពាក់អ្នកផ្គត់ផ្គង",
        "id": "លេខសម្គាល់",
        "cost-of-goods": "ថ្លៃដើមទិញទំនិញ",
        "cross-profit-loss": "ប្រាក់ចំណេញ/ខាតបង់សរុប",
        "operating-expenses": "ចំណាយប្រតិបត្តិការ",
        "Cash-In": "ដាក់លុយ",
        "Cash-Out": "ដកលុយ",
        "Commission": "កម្រៃសេវា",
        "Insurance": "ធនារាប់រង",
        "Marketing Expenses": "ចំណាយការធ្វើទីផ្សារ",
        "Office Expenses": "ការចំណាយការិយាល័យ",
        "Professional Fee": "ថ្លៃឈ្នួលសេវា",
        "Wage": "ប្រាក់ឈ្នួល",
        "net-operating-profit-loss": "ប្រាក់ចំណេញ/ខាតប្រតិបត្តិការសុទ្ធ ",
        "pdf": "PDF",
	    "excel": "Excel",
        "all-store": "ហាងទាំងអស់",
        "filter": "តម្រង",
        "clear-filter": "លុបតម្រង",
        "start": "ចាប់ផ្តើម",
		"end": "បញ្ចប់",
		"open-invoice": "វិក័យប័ត្រមិនបានទូទាត់",
        "total-cash-out": "ចំនួនដកប្រាក់",
        "open-cash-amount": "ចំនួនប្រាក់បើកលក់",
		"service-charge": "ថ្លៃសេវា",
		"unpaid-invoice": "វិក័យប័ត្រមិនបានទូទាត់",
        "open-cash": "ប្រាក់បើកលក់",
        "key": "Key",
        "identity": "អត្តសញ្ញាណ",
        "amount-receive": "ចំនួនបានទទួល",
        "amount-return": "ចំនួនបញ្ជូនត្រលប់",
        "expense-type": "ប្រភេទចំណាយ",
        "graph": "Graph",
        "po-table": "បញ្ជីបញ្ជាទិញ",
        "invoice-no": "លេខវិក័យប័ត្រ",

    } 
}