import { LOCALES } from '../locales'

export default {
    [LOCALES.ENGLISH]: {
        "login": "Login",

        // Menu
        "dashboard": "Dashboard",
        "product": "Product",
        "cate": "Category",
        "brand": "Brand",
        "type": "Type",
        "inventory": "Inventory",
        "store": "Store",
        "warehouse": "Warehouse",
        "receive-po": "Receive PO",
        "purchasing": "Purchasing",
        "po": "PO",
        "setprice": "Set Price",
        "pricing": "Pricing",
        "check-price": "Check Price",
        "marketing": "Marketing",
        "program": "Program",
        "loyalty": "Loyalty",
        "e-wallet": "E-Wallet",
        "people": "People",
        "user": "User",
        "vendor": "Vendor",
        "customer": "Customer",
        "group": "Group",
        "report": "Report",
        "sale": "Sale",
        "expense": "Expense",
        "setting": "Setting",
        "general": "General",
        "role": "Role",
        "counter": "Counter",
        "currency": "Currency",
        "profile": "Profile",
        "support": "Support",

        // Base Index Layout
        "search": "Search",
        "tool": "Tool",
        "total": "Total",
        "no-data": "No Data",
        "no": "No.",
        "action": "Action",

        // Product
        "all": "All",
        "code": "Code",
        "sku": "SKU",
        "product-name": "Product Name",

        // User
        "create-user": "Create User",
        "edit-user": "Edit User",
        "username": "Username",
        "email": "Email",
        "phone-number": "Phone Number",
        "designation": "Designation",
        "image": "Image",
        "first-name": "First Name",
        "last-name": "Last Name",
        "address": "Address",
        "city": "City",
        "zipcode": "Zipcode",
        "country": "Country",
        "map-url": "Map URL",
        "role-active": "Create new role?",
        "security": "Security",
        "password": "Password",
        "confirm-pwd": "Confirm Password",
        "shift-time": "Shift Time",
        "confirm-pwd": "Confirm Password",
        "confirm-pwd": "Confirm Password",
        "name": "Name",
        "time": "Time",

        // Entity Base Layout
        "save": "Save",
        "discard": "Discard",
        "update": "Update",
        "print": "Print",
        "upload-pic": "Upload Picture",
        "description": "Description",
        "company": "Company",
        "full-name": "Full Name",
        "create-group": "Create Group",
        "edit-group": "Edit Group",
        "view-group": "View Group",
        "create-vendor": "Create Vendor",
        "edit-vendor": "Edit Vendor",
        "view-vendor": "View Vendor",
        "create-product": "Create Product",
        "edit-product": "Edit Product",
        "show-product": "Show Product",
        "create-brand": "Create Brand",
        "edit-brand": "Edit Brand",
        "show-brand": "Show Brand",
        "create-cate": "Create Category",
        "edit-cate": "Edit Category",
        "show-cate": "Show Category",
        "create-sub-cate": "Create Sub-Category",
        "edit-sub-cate": "Edit Sub-Category",
        "show-sub-cate": "Show Sub-Category",
        "edit-type": "Edit Type",
        "show-type": "Show Type",
        "pwd-active": "Want to change new password?",
        "brand-active": "Make this brand available",
        "tags": "Tags",
        "status": "Status",
        "available": "Available",
        "unavailable": "Unavailable",
        "sub-cate": "Sub-Category",
        "prefix": "Prefix",
        "pn-sku": "PN/SKU",
        "model": "Model",
        "native-name": "Native Name",
        "variant": "Variant",
        "value": "Value",
        "specification": "Specification",
        "variant-active": "Is this product has variant?",
        "no-variant": "No Variant",
        "unit": "Unit",
        "priority": "Priority",
        "inhouse-product": "In House Product",
        "product-image": "Product Image",
        "import": "Import",
        "export": "Export",
        "template": "Template",
        "contact-info": "Contact Info",
        "place": "Place",
        "resturant-ktv": "Resturant & KTV",
        "area": "Area",
        "table": "Table",
        "room": "Room",
        "full-name": "Full Name",
        "fax": "Fax",
        "tin": "TIN",
        "tin-full": "Tax Identification Number", 
        "registered-at": "Registerd At",
        "main-business": "Main Business",
        "under": "Under",
        "form-business": "Form Business",
        "nationality": "Nationality",
        "facebook": "Facebook",
        "website": "Website",
        "gender": "Gender",
        "group-active": "Want to create a new group?",
        "create-customer": "Create Customer",
        "edit-customer": "Edit Customer",
        "view-customer": "View Customer",
        "male": "Male",
        "female": "Female",
        "permission": "Permission",
        "create-role": "Create Role",
        "edit-role": "Edit Role",
        "change-pwd": "Want to change new password?",
        "wifi-name": "WiFi Name",
        "wifi-pwd": "WiFi Password",
        "thank-you-text": "Thank You Text",
        "position-info": "Position Info",
        "position": "Position",
        "create-warehouse": "Create Warehouse",
        "edit-warehouse": "Edit Warehouse",
        "view-warehouse": "View Warehouse",
        "product-name": "Product Name",
        "brand-name": "Brand Name",
        "create-set-price": "Create Set Price",
        "edit-set-price": "Edit Set Price",
        "product-info": "Product Info",
        "price-to-lvl": "Price Level",
        "start-price": "Start Price",
        "end-price": "End Price",
        "attach-file": "Attach File",
        "image-file": "Image and File",
        "qty": "Qty",
        "back": "Back",
        "bundle": "Bundle",
        "files": "Files",
        "store-location": "Store Location",
        "warehouse-location": "Warehouse Location",
        "staff": "Staff",
        "create-staff": "Create Staff",
        "edit-staff": "Edit Staff",
        "department": "Department",
        "create-department": "Create Department",
        "edit-department": "Edit Department",
        "po-num": "PO Number",
        "payment": "Payment",
        "create-by": "Created By",
        "receive-by": "Received By",
        "create-at": "Created At",
        "update-at": "Updated At",
        "cost": "Cost",
        "total-cost": "Total Cost",
        "made-date": "Made Date",
        "exp-date": "Exp Date",
        "remark": "Remark",
        "cost-active": "Is this po has another costs?",
        "deposit": "Deposit",
        "tax": "Tax",
        "estimate-arrival": "Estimate Arrival",
        "note": "Note",
        "color": "Color",
        "clear-by": "Clear By",
        "create-po": "Create PO",
        "edit-po": "Edit PO",
        "receive-po": "Receive PO",
        "edit-receive-po": "Edit Receive PO",
        "receive": "Receive",
        "return": "Retrun",
        "amount": "Amount",
        "ordering": "Ordering",
        "receiving": "Receiving",
        "issued-considering": "Issued Considering",
        "returning-all": "Returning All",
        "returning-some-items": "Returning Some Items",
        "completed-received-all": "Completed Received All",
        "completed-by-over": "Completed By Over",
        "completed-by-missing": "Completed By Missing",
        "completed-by-broken": "Completed By Broken No Return",
        "completed-by-return-some": "Completed By Return Some Items",
        "serial-number": "Serial Number",
        "warranty": "Warranty",
        "receive-to": "Receive To",
        "clear-po": "Clear PO",
        "deposit-remark": "Deposit Remark",
        "total-po": "Total PO",
        "vendor-address": "Vendor Address",
        "total-ordering": "Total Ordering",
        "total-receiving": "Total Receiving",
        "total-succeed": "Total Succeed",
        "total-issued": "Total Issued",
        "total-cancelled": "Total Cancelled",
        "total-draft": "Total Draft",
        "total-delete": "Total Delete",
        "total-amount": "Total Amount",
        "total-paid": "Total Paid",
        "total-credit": "Total Credit",
        "draft": "Draft",
        "cancelled": "Cancelled",
        "date": "Date",
        "product-code": "Product Code",
        "adjustment": "Adjustment",
        "booking": "Booking",
        "reserve": "Reserve",
        "request": "Request",
        "transfer": "Transfer",
        "warehouse-request": "Warehouse Request",
        "warehouse-transfer": "Warehouse Transfer",
        "store-request": "Store Request",
        "store-transfer": "Store Transfer",
        "ref-quot-no": "Reference Quotation No",
        "refer-down-pay-no": "Reference Down Payment No",
        "project-name": "Project Name",
        "client": "Client",
        "sale-represent": "Sale Represent",
        "num-of-day": "Numbers of Day",
        "reason": "Reason",
        "create-booking": "Create Booking",
        "edit-booking": "Edit Booking",
        "check-booking": "Check Booking",
        "view-booking": "View Booking",
        "ok": "OK",
        "cancel": "Cancel",
        "instock": "In Stock",
        "information": "Information",
        "ref-pro-inv-no": "Reference Proforma Invoice No",
        "next-pay-est-date": "Next Payment Estimate Date",
        "request-from": "Request From",
        "request-to": "Request To",
        "core-feature": "Core Feature",
        "technical-sale": "Technical Sale",
        "executive-summary": "Executive Summary",
        "full-description": "Full Description",
        "attached-file": "Attached File",
        "this-week": "This Week",
        "this-month": "This Month",
        "this-year": "This Year",
        "nearly-out-of-stock": "Nearly Out of Stock",
        "nearly-expire-product": "Nearly Expire Product",
        "cut-stock": "Cut Stock",
        "add-stock": "Add Stock",
        "current-stock": "Current Stock",
        "broken": "Broken",
        "lost": "Lost",
        "other": "Other",
        "additional-stock": "Additional Stock",
        "vendor-promotion": "Vendor Promotion",
        "staff": "Staff",
        "animal": "Animal",
        "life-value": "Life Value",
        "penalty": "Penalty",
        "extra-cost": "Extra Cost",
        "by": "By",
        "open-reviewing": "Open-Reviewing",
        "open-rejected": "Open-Rejected",
        "closed-approved": "Closed-Approved",
        "closed-rejected": "Closed-Rejected",
        "own-request": "Own Request",
        "own-transfer": "Own Transfer",
        "other-request": "Other Request",
        "other-transfer": "Other Transfer",
        "reviewing": "Reviewing",
        "revision-item": "Revision Item",
        "reject": "Reject",
        "accepted": "Accepted",
        "preparing": "Preparing",
        "delivering": "Delivering",
        "receiving": "Receiving",
        "completed": "Completed",
        "execution": "Execution",
        "issue-date": "Issued Date",
        "lastest-update": "Lastest Update",
        "record-number": "Record Number",
        "transfer-to": "Transfer To",
        "request-from": "Request From",
        "transfer-from": "Transfer From",
        "acknowledge-by": "Acknowledge By",
        "point": "Point",
        "set-point": "Set Point",
        "start-point": "Start Point",
        "end-point": "End Point",
        "loyalty-active": "Make this loyalty available",
        "create-loyalty": "Create Loyalty",
        "edit-loyalty": "Edit Loyalty",
        "balance": "Balance",
        "serial": "Serial",
        "create-counter": "Create Counter",
        "edit-counter": "Edit Counter",
        "create-e-wallet": "Create E-Wallet",
        "top-up-e-wallet": "Topup E-Wallet",
        "dollar": "Dollar",
        "riel": "Riel",
        "stand-alone-active": "Is this a stand alone program?",
        "create-program": "Create Program",
        "edit-program": "Edit Program",
        "check-program": "Check Program",
        "view-program": "View Program",
        "program-name": "Program Name",
        "program-id": "Program ID",
        "message": "Message",
        "price-limit": "Price Limit",
        "cash-type": "Cash Type",
        "schedule": "Schedule",
        "item": "Item",
        "is-zero": "Include Zero",
        "create-request": "Create Request",
        "edit-request": "Edit Request",
        "check-request": "Check Request",
        "view-request": "View Request",
        "create-transfer": "Create Transfer",
        "edit-transfer": "Edit Transfer",
        "check-transfer": "Check Transfer",
        "view-transfer": "View Transfer",
        // "edit-counter": "Edit Counter",
        "invoice": "Invoice",
        "today-invoice": "Today Invoice",
        "sale-revenue": "Sale Revenue",
        "today-sale-revenue": "Today Sale Revenue",
        "today": "Today",
        "week": "Week",
        "month": "Month",
        "year": "Year",
        "yesterday": "Yesterday",
        "last-week": "Last Week",
        "last-month": "Last Month",
        "last-year": "Last Year",
        "credit": "Credit",
        "sale-report": "Sale Report",
        "po-report": "PO Report",
        "today-credit": "Today Credit",
        "new-arrival": "New Arrival",
        "nearly-exp": "Nearly Expire",
        "nearly-out": "Nearly Out of Stock",
        "financial": "Financial",
        "invoice-num": "Invoice Number",
        "receive-dollar": "Receive ($)",
        "receive-riel": "Receive (៛)",
        "credit-dollar": "Credit ($)",
        "credit-riel": "Credit (៛)",
        "total-dollar": "Total ($)",
        "total-riel": "Total (៛)",
        "method": "Method",
        "withdraw": "Withdraw",
        "product-report": "Product Report",
        "inventory-report": "Inventory Report",
        "main-balance": "Main Balance",
        "num-of-exp": "Alert Number of Expire Date (Day)",
        "num-of-out-stock": "Alert Number of Out of Stock (Qty)",
        "money-in": "Money In",
        "money-out": "Money Out",
        "is-serial": "Has Serial Number?",
        "accounting": "Accounting",
        "credit-management": "Credit Management",
        "credit-collection": "Credit Collection",
        "credit-settlement": "Credit Settlement",
        "total-clients": "Total Clients",
        "total-transactions": "Total Transactions",
        "total-credit-settled": "Total Credit Settled",
        "due-credit-balance": "Due Credit Balance",
        "client-id": "Client ID",
        "client-name": "Client Name",
        "amount-should-be-settled": "Amount Should Be Settled",
        "settle": "Settle",
        "clear-credit": "Clear Credit",
        "invoice-number": "Invoice Number",
        "sale-representative": "Sale Representative",
        "down-payment": "Down Payment",
        "purchased-date": "Purchased Date",
        "latest-updated-date": "Latest Updated Date",
        "estimate-return-date": "Estimate Return Date",
        "sub-total": "Sub Total",
        "discount": "Discount",
        "vat": "Vat",
        "grand-total": "Grand Total",
        "account": "Account",
        "loging": "Loging...",
        "product-management": "Product Management",
        "auto-settle": "Auto Settle",
        "view-credit": "View Credit",
        "credit-balance": "Credit Balance",
        "english": "English",
        "khmer": "Khmer",
        "create-type": "Create Type",
        "create-adjustment": "Create Adjustment",
        "inventory-management": "Inventory Management",
        "view-receive-po": "View Receive PO",
        "hr": "HR",
        "create-client": "Create Client",
        "edit-client": "Edit Client",
        "view-client": "View Client",
        "view-po": "View PO",
        "sale-history": "Sales History",
        "sale-cash-management": "Sales Cash Management",
        "sale-client-management": "Sales Client Management",
        "payway": "Payway",
        "debit": "Debit",
        "marketing-report": "Marketing Report",
        "tax-value": "Tax Value",
        "service-value": "Service Value",
        "tax-status": "Is this store has tax?",
        "service-status": "Is this store has service charge?",
        "use-native-name": "Want to use native name?",
        "logo": "Logo",
        "full-logo": "Full Logo",
        "kot-department": "Kot Department",
        "is-kitchen": "Wanna display in kitchen?",
        "reward-point": "Reward Point 1$ = X Point(s)",
        "exchange-reward-point": "Exchange Reward Point 1 Point = X Dollar",
        "member-point": "Member Point 1$ = X Member Point(s)",
        "all-brands": "All Brands",
        "all-categories": "All Categories",
        "all-sub-categories": "All Sub Categories",
        "all-types": "All Types",
        "price": "Price",
        "update-by": "Update By",
        "approve-by": "Approve By",
        "item-type": "Item Type",
        "client-type": "Client Type",
        "rule": "Rule",
        "number-of-exp": "Number of Expire Date",
        "number-of-new-arrival": "Number of New Arrival",
        "membercard": "Member Card",
        "tel": "Tel",
        "contact": "Contact",
        "sale-insight": "Sale Insight",
        "sale-detail": "Sale Detail",
        "item-wise": "Item Wise",
        "cash-control": "Cash Control",
        "total-sold": "Total Sold",
        "grand-total-dollar": "Grand Total Dollar",
        "grand-total-riel": "Grand Total Riel",
        "total-receive-dollar": "Total Receive Dollar",
        "total-receive-riel": "Total Receive Riel",
        "total-change-dollar": "Total Change Dollar",
        "total-change-riel": "Total Change Riel",
        "change-dollar": "Change Dollar",
        "change-riel": "Change Riel",
        "receipt-no": "Receipt N.O",
        "cashier": "Cashier",
        "total-bills": "Total Bills",
        "sale-summary": "Sales Summary",
        "gross-sale": "Gross Sale",
        "net-sale": "Net Sale",
        "cash-in": "Cash In",
        "expensed": "Expensed",
        "sold": "Sold",
        "changed": "Changed",
        "cash": "Cash",
        "paid": "Paid",
        "transaction": "Transaction",
        "search-client": "Search Client",
        "customer-service-id": "Customer Service Id",
        "adjustment-cut": "Adjustment Cut",
        "adjustment-add": "Adjustment Add",
        "booking-by": "Booking By",
        "reserve-by": "Reserve By",
        "use-promotion": "Is this store use marketing promotion?",
        "payment-qr-dollar": "Payment QR Code USD",
        "payment-qr-riel": "Payment QR Code KHR",
        "busy-status": "Busy Status",
        "counter-active": "Make this counter available in this store",
        "icon": "Icon",
        "create-payway": "Create Payway",
        "edit-payway": "Edit Payway",
        "create-debit": "Create Debit",
        "edit-debit": "Edit Debit",
        "sales-reports": "Sales Reports",
        "sales-history": "Sales History",
        "sale-cash-in-out": "Sales Cash In Out",
        "sale-currency-exchange": "Sales Currency Exchange",
        "sale-e-wallet": "Sales Wallet",
        "sale-user-profile": "Sales User Profile",
        "total-paid-status": "Total Paid Status",
        "total-credit-status": "Total Credit Status",
        "total-withdraw": "Total Withdraw",
        "select": "Select",
        "each-set-price": "Each Set Price",
        "new": "New",
        "receipt": "Receipt",
        "bill-no": "Bill No",
        "sales-associate": "Sales Associate",
        "paid-by": "Paid By",
        "dis": "Dis.",
        "received": "Received",
        "exchange-rate": "Exchange Rate",
        "pre-sale": "Pre-Sale",
        "counter-is-logout": "Turn this counter to offline",
        "total-walk-in": "Total Walk In",
        "today-walk-in": "Today Walk In",
        "walk-in": "Walk In",
        "price-list": "Price List",
        "received-payment-remark": "Received Payment Remark",
        "due-balance": "Due Balance",
        "total-point": "Total Point",
        "set-price": "Set Price",
        "cooking-time": "Cooking Time",
        "create-kot-department": "Create Kot Department",
        "edit-kot-department": "Edit Kot Department",
        "is-pre-sale": "Is Pre-Sale?",
        "department-active": "Create new department?",
        "sale-receipt": "Sale Receipt",
        "sale-bill": "Sale Bill",
        "pos-shift": "Pos Shift",
        "payment-received": "Payment Received",
        "expenses": "Expenses",
        "total-bill": "Total Bill",
        "item-code": "Item Code",
        "category": "Category",
        "sub-category": "Sub-Category",
        "qty-sold": "Qty Sold",
        "total-customer": "Total Customer",
        "total-item-sold": "Total Item Sold",
        "shift-no": "Shift No.",
        "cashier-name": "Cashier Name",
        "open-date": "Open Date",
        "close-date": "Close Date",
        "close-usd": "Close USD",
        "close-khr": "Close KHR",
        "different-usd": "Different USD",
        "different-khr": "Different KHR",
        "transaction-details": "Transaction Details",
        "total-payway": "Total Payway",
        "total-expense": "Total Expense",
        "total-transaction": "Total Transaction",
        "create-store": "Create Store",
        "edit-store": "Edit Store",
        "account-name": "Account Name",
        "account-native-name": "Account Native Name",
        "payway-name": "Payway Name",
        "payway-native-name": "Payway Native Name",
        "account-card": "Account Card",
        "payway-link": "Payway Link",
        "payway-active": "Is this available?",
        "is-dollar-status": "Is this a dollar?",
        "link": "Link",
        "paid-invoice": "Paid Invoice",
        "void-invoice": "Void Invoice",
        "total-invoice": "Total Invoice",
        "cash-in-amount": "Cash In Amount",
        "receive-amount": "Receive Amount",
        "total-change": "Total Change",
        "total-expensed": "Total Expensed",
        "total-balance": "Total Balance",
        "received-via-payway": "Received via Payway",
        "real-cash-count": "Real Cash Count",
        "different-cash": "Different Cash",
        "reason-of-different": "Reason of Different",
        "total-qty": "Total QTY",
        "payment-group": "Payment Group",
        "card-topup": "Card Topup",
        "stock": "Stock",
        "in": "In",
        "out": "Out",
        "waiter": "Waiter",
        "exchage": "Exchage",
        "change": "Change",
        "capital": "Capital",
        "check-in": "Check In",
        "check-out": "Check Out",
        "subscription": "Subscription",
        "view-subscription": "View Subscription",
        "remaining-day": "Remaining Day",
        "bill-cycle": "Bill Cycle",
        "service-subscription": "Service Subscription",
        "tools": "Tools",
        "duration": "Duration",
        "remaining": "Remaining",
        "client-management": "Client Management",
        "restaurant-ktv": "Restaurant & KTV",
        "create-area": "Create Area",
        "edit-area": "Edit Area",
        "create-table": "Create Table",
        "edit-table": "Edit Table",
        "create-room": "Create Room",
        "edit-room": "Edit Room",
        "rental-room": "Rental Room",
        "create-rental-room": "Create Rental Room",
        "edit-rental-room": "Edit Rental Room",
        "available-store": "Available for Store",
        "profit-and-loss": "Profit and Loss",
        "account-receivable": "Account Receivable",
        "account-payable": "Account Payable",
        "id": "ID",
        "cost-of-goods": "Cost of Goods",
        "cross-profit-loss": "Cross Profit/Loss",
        "operating-expenses": "Operating Expenses",
        "Cash-In": "Cash-In",
        "Cash-Out": "Cash-Out",
        "Commission": "Commission",
        "Insurance": "Insurance",
        "Marketing Expenses": "Marketing Expenses",
        "Office Expenses": "Office Expenses",
        "Professional Fee": "Professional Fee",
        "Wage": "Wage",
        "net-operating-profit-loss": "Net Operating Profit/Loss",
        "pdf": "PDF",
		"excel": "Excel",
        "all-store": "All Stores",
        "filter": "Filter",
        "clear-filter": "Clear Filter",
        "start": "Start",
		"end": "End",
		"open-invoice": "Open Invoice",
		"total-cash-out": "Total Cash-Out",
		"open-cash-amount": "Open Cash Amount",
		"service-charge": "Service Charge",
		"unpaid-invoice": "Unpaid Invoice",
		"open-cash": "Open Cash",
        "key": "Key",
        "identity": "Identity",
        "amount-receive": "Amount Receive",
        "amount-return": "Amount Return",
        "no-address": "No Address",
        "withdraw-active": "Withdraw?",
        "expense-type": "Expense Type",
        "graph": "Graph",
        "po-table": "Table",
        "invoice-no": "Invoice No.",
        
        // Tooltip
        "tooltip-deposit": "Tooltip Deposit",
        "tooltip-tax": "Tooltip Tax",
        "tooltip-estimate-arrival": "Tooltip Estimate Arrival",
        "tooltip-vendor": "Tooltip Vendor",
        "tooltip-vendor-address": "Tooltip Vendor Address",
        "tooltip-note": "Tooltip Note",
        "tooltip-pn-sku": "Tooltip PN/SKU",
        "tooltip-product-name": "Tooltip Product Name",
        "tooltip-product-code": "Tooltip Product Code",
        "tooltip-product-native-name": "Tooltip Product Native Name",
        "tooltip-priority": "Tooltip Priority",
        "tooltip-model": "Tooltip Model",
        "tooltip-brand": "Tooltip Brand",
        "tooltip-cate": "Tooltip Category",
        "tooltip-sub-cate": "Tooltip Sub Category",
        "tooltip-tags": "Tooltip Tags",
        "tooltip-num-of-exp": "Tooltip Number of Expire Date",
        "tooltip-num-of-out-stock": "Tooltip Number of Out of Stock",
        "tooltip-inhouse-product": "Tooltip Inhouse Product",
        "tooltip-is-serial": "Tooltip Is Serial",
        "tooltip-status": "Tooltip Status",
        "tooltip-type": "Tooltip Type",
        "tooltip-variant": "Tooltip Variant",
        "tooltip-variant-active": "Tooltip Variant Active",
        "tooltip-image-file": "Tooltip Image and File",
        "tooltip-description": "Tooltip Description",
        "tooltip-specification": "Tooltip Specification",
        "tooltip-core-feature": "Tooltip Core Feature",
        "tooltip-technical-sale": "Tooltip Technical Sale",
        "tooltip-executive-summary": "Tooltip Executive Summary",

        // Status Code
        "404": "API Not Found !",
        "422": "Validation Error !",
        "500": "Server Error !",

        // Month
        "jan": "January",
        "feb": "February",
        "mar": "March",
        "apr": "April",
        "may": "May",
        "jun": "June",
        "jul": "July",
        "aug": "August",
        "sep": "September",
        "oct": "Octorber",
        "nov": "November",
        "dec": "December",
    } 
}